/**
 *
 * Button
 *
 */
import * as React from "react";
import styled from "styled-components/macro";
import { Button as AntdButton } from "antd";
import { COLORS } from "../utils/colors";

interface Props {
  hideshadow?: boolean;
}

const CustomButton = styled(AntdButton)`
  border-radius: 6px;
  box-shadow: ${(props: Props) =>
    !props.hideshadow && "0px 0px 10px rgba(33, 122, 231, 0.7)"};
`;

export function Button(props: Props & React.ComponentProps<typeof AntdButton>) {
  const { style = {}, disabled } = props;
  return (
    <CustomButton
      {...props}
      style={{
        ...style,
        ...(disabled
          ? {
              backgroundColor: COLORS.darkGray,
              opacity: 0.3,
              color: COLORS.nearWhite,
            }
          : {}),
      }}
    />
  );
}

import * as React from "react";
import styled from "styled-components/macro";

import { StyleConstants } from "styles/StyleConstants";

import { Button } from "./Button";

import { COLORS } from "../utils/colors";
import { Link } from "gatsby";

const PageWrapper = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding: 0 18px;
`;

const Logo = (props: { color?: string }) => {
  const { color = COLORS.nearWhite } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div
        style={{
          marginLeft: 8,
          fontSize: 18,
          fontWeight: 500,
          color: color,
          whiteSpace: "nowrap",
        }}
      >
        tryapis.com
      </div>
    </div>
  );
};

const LinkButton = styled(Button)`
  color: ${COLORS.darkGray};
`;

export function NavBar({
  cta,
  onCtaClick,
  secondaryCta,
  onSecondaryCtaClick,
}: {
  cta?: string;
  onCtaClick?: () => void;
  secondaryCta?: string;
  onSecondaryCtaClick?: () => void;
}) {
  return (
    <>
      <Wrapper>
        <Container>
          <Link to="/" style={{ textDecoration: "none !important" }}>
            <Logo />
          </Link>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {secondaryCta && (
              <LinkButton
                type="link"
                onClick={onSecondaryCtaClick}
                style={{ color: `${COLORS.darkGray} !important` }}
                hideshadow
              >
                {secondaryCta}
              </LinkButton>
            )}
            {cta && (
              <Button type="primary" onClick={onCtaClick}>
                {cta}
              </Button>
            )}
          </div>
        </Container>
      </Wrapper>
    </>
  );
}

const Container = styled.div`
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  padding: 0 18px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.header`
  // Sizing

  // Other

  height: 4rem;
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  backdrop-filter: blur(5px);
  background-color: rgba(31, 33, 36, 0.7);
`;
